// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Qs from "qs";
import * as $$Date from "../libs/Date.res.js";
import * as Provider from "./Provider.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

var DocumentId = ID.Int({});

function fromJs($$document) {
  return {
          id: $$document.id,
          filename: $$document.filename,
          byteSize: $$document.byteSize,
          createdAt: $$Date.Utc.fromString($$document.createdAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", DocumentId.decoder),
              filename: field.required("filename", Json_Decode$JsonCombinators.string),
              byteSize: field.required("byteSize", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Utc.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toKiloBytes(bytes) {
  return bytes / 1000.0;
}

function toMegaBytes(bytes) {
  return bytes / 1000000.0;
}

function sizeText(bytes) {
  if (bytes < 1000000) {
    return (bytes / 1000.0).toFixed(1) + " KB";
  } else {
    return (bytes / 1000000.0).toFixed(1) + " MB";
  }
}

var $$Document = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson,
  toKiloBytes: toKiloBytes,
  toMegaBytes: toMegaBytes,
  sizeText: sizeText
};

function fromJs$1(js) {
  return {
          id: js.id,
          providerId: js.providerId,
          title: js.title,
          subtitle: js.subtitle,
          description: js.description,
          image: js.image,
          slug: js.slug,
          pdfFile: js.pdfFile,
          privateDownload: js.privateDownload,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          pdfResource: fromJs(js.pdfResource),
          downloadsCount: js.downloadsCount,
          ahoyEventsCount: js.ahoyEventsCount,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              subtitle: field.required("subtitle", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              pdfFile: field.required("pdfFile", Json_Decode$JsonCombinators.string),
              privateDownload: field.required("privateDownload", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              pdfResource: field.required("pdfResource", decoder),
              downloadsCount: field.required("downloadsCount", Json_Decode$JsonCombinators.$$int),
              ahoyEventsCount: field.required("ahoyEventsCount", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          providerBrochure: fromJs$1(js.providerBrochure),
          provider: Provider.ProviderBrochure.fromJs(js.provider)
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providerBrochure: field.required("providerBrochure", decoder$1),
              provider: field.required("provider", Provider.ProviderBrochure.decoder)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Show = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2
};

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              title: field.required("title", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Sidebar = {
  decoder: decoder$3,
  fromJson: fromJson$3
};

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providerBrochures: field.required("providerBrochures", Json_Decode$JsonCombinators.array(decoder$2)),
              popularBrochures: field.required("popularBrochures", Json_Decode$JsonCombinators.array(decoder$3)),
              recentBrochures: field.required("recentBrochures", Json_Decode$JsonCombinators.array(decoder$3)),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalProviderBrochures: field.required("totalProviderBrochures", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var Index = {
  decoder: decoder$4,
  fromJson: fromJson$4
};

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerSlug: field.required("providerSlug", Json_Decode$JsonCombinators.string),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              subtitle: field.required("subtitle", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              pdfFile: field.required("pdfFile", Json_Decode$JsonCombinators.string),
              privateDownload: field.required("privateDownload", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              pdfResource: field.required("pdfResource", decoder),
              downloadsCount: field.required("downloadsCount", Json_Decode$JsonCombinators.$$int),
              ahoyEventsCount: field.required("ahoyEventsCount", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

var Dashboard = {
  decoder: decoder$5,
  fromJson: fromJson$5
};

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              subtitle: field.required("subtitle", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              pdfFile: field.required("pdfFile", Json_Decode$JsonCombinators.string),
              privateDownload: field.required("privateDownload", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              pdfResource: field.required("pdfResource", decoder),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var Edit = {
  decoder: decoder$6,
  fromJson: fromJson$6
};

function fromJs$3(js) {
  return {
          id: js.id,
          title: js.title,
          subtitle: js.subtitle,
          privateDownload: js.privateDownload,
          pdfFile: js.pdfFile,
          image: js.image
        };
}

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              subtitle: field.required("subtitle", Json_Decode$JsonCombinators.string),
              privateDownload: field.required("privateDownload", Json_Decode$JsonCombinators.string),
              pdfFile: field.required("pdfFile", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$7(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$7));
}

var DropdownItem = {
  fromJs: fromJs$3,
  decoder: decoder$7,
  fromJson: fromJson$7
};

var decoder$8 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providerBrochures: field.required("providerBrochures", Json_Decode$JsonCombinators.array(decoder$7))
            };
    });

function fromJson$8(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$8));
}

var DropdownList = {
  decoder: decoder$8,
  fromJson: fromJson$8
};

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var Sort = {};

function toString$1(x) {
  if (x.NAME === "Title") {
    return "provider_brochures.title";
  } else {
    return "providers.name";
  }
}

function toJson(sortBy) {
  return Json_Encode$JsonCombinators.object([
              [
                "sortBy",
                toString$1(sortBy)
              ],
              [
                "sortDirection",
                toString(sortBy.VAL)
              ]
            ]);
}

function toQueryString(sortBy) {
  return Qs.stringify(toJson(sortBy), {
              arrayFormat: "brackets"
            });
}

var SortBy = {
  toString: toString$1,
  toJson: toJson,
  toQueryString: toQueryString
};

export {
  DocumentId ,
  $$Document ,
  fromJs$1 as fromJs,
  decoder$1 as decoder,
  fromJson$1 as fromJson,
  Show ,
  Sidebar ,
  Index ,
  Dashboard ,
  Edit ,
  DropdownItem ,
  DropdownList ,
  SortDirection ,
  Sort ,
  SortBy ,
}
/* DocumentId Not a pure module */
