// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";

function exec(providerBrochureId) {
  return Rest.$$fetch("/dashboard/provider_brochures/" + ID.toString(providerBrochureId), {
              NAME: "Delete",
              VAL: undefined
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
