// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateProviderBrochureRequest from "./requests/CreateProviderBrochureRequest.res.js";
import * as UpdateProviderBrochureRequest from "./requests/UpdateProviderBrochureRequest.res.js";
import * as DestroyProviderBrochureRequest from "./requests/DestroyProviderBrochureRequest.res.js";
import * as ActivateProviderBrochureRequest from "./requests/ActivateProviderBrochureRequest.res.js";
import * as DeactivateProviderBrochureRequest from "./requests/DeactivateProviderBrochureRequest.res.js";
import * as FetchProviderBrochuresIndexRequest from "./requests/FetchProviderBrochuresIndexRequest.res.js";
import * as NotifyProviderBrochureDownloadRequest from "./requests/NotifyProviderBrochureDownloadRequest.res.js";
import * as FetchProviderBrochuresDashboardRequest from "./requests/FetchProviderBrochuresDashboardRequest.res.js";
import * as ActivateProviderBrochuresInBatchRequest from "./requests/ActivateProviderBrochuresInBatchRequest.res.js";

var create = CreateProviderBrochureRequest.exec;

var destroy = DestroyProviderBrochureRequest.exec;

var update = UpdateProviderBrochureRequest.exec;

var dashboard = FetchProviderBrochuresDashboardRequest.exec;

var activate = ActivateProviderBrochureRequest.exec;

var deactivate = DeactivateProviderBrochureRequest.exec;

var activateInBatch = ActivateProviderBrochuresInBatchRequest.exec;

var notifyDownload = NotifyProviderBrochureDownloadRequest.exec;

var index = FetchProviderBrochuresIndexRequest.exec;

export {
  create ,
  destroy ,
  update ,
  dashboard ,
  activate ,
  deactivate ,
  activateInBatch ,
  notifyDownload ,
  index ,
}
/* CreateProviderBrochureRequest Not a pure module */
