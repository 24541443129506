// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";

function exec(providerBrochureId) {
  return Rest.$$fetch("/provider_brochures/" + (ID.toString(providerBrochureId) + "/notify_download"), {
              NAME: "Post",
              VAL: null
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
