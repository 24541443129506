// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Csrf from "../../Csrf.res.js";
import * as Http from "../../Http.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ProviderBrochure from "../../../models/ProviderBrochure.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";

function exec(title, subtitle, description, metaTitle, metaDescription, $$document, active, providerId) {
  var formData = new FormData();
  formData.append("title", title);
  formData.append("subtitle", subtitle);
  formData.append("description", description);
  formData.append("meta_title", metaTitle);
  formData.append("meta_description", metaDescription);
  formData.append("document", $$document);
  formData.append("active", active);
  formData.append("provider_id", providerId);
  return $$Promise.andThen(fetch("/api/v1/dashboard/provider_brochures", {
                  method: Http.Method.toFetch({
                        NAME: "Post",
                        VAL: undefined
                      }),
                  body: Caml_option.some(formData),
                  headers: Caml_option.some(new Headers([[
                              "X-CSRF-Token",
                              Belt_Option.getExn(Csrf.token())
                            ]])),
                  credentials: "same-origin"
                }), (function (response) {
                if (response.ok) {
                  return $$Promise.map(response.json(), (function (json) {
                                return {
                                        TAG: "Ok",
                                        _0: Belt_Result.getExn(Json$JsonCombinators.decode(json, ProviderBrochure.Edit.decoder))
                                      };
                              }));
                }
                var match = Http.ContentType.fromHeaders(response.headers);
                if (match !== undefined && typeof match !== "object") {
                  switch (match) {
                    case "Text" :
                        return $$Promise.map(response.text(), (function (text) {
                                      SentryLogger.error4({
                                            rootModule: "CreateProviderBrochureRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "CreateProviderBrochureRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "text"
                                          ], [
                                            "responseBody",
                                            text
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    case "Json" :
                        return $$Promise.map(response.json(), (function (json) {
                                      SentryLogger.error4({
                                            rootModule: "CreateProviderBrochureRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "CreateProviderBrochureRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "json"
                                          ], [
                                            "responseBody",
                                            json
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    case "Blob" :
                        return $$Promise.map(response.blob(), (function (blob) {
                                      SentryLogger.error4({
                                            rootModule: "CreateProviderBrochureRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "CreateProviderBrochureRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "blob"
                                          ], [
                                            "responseBody",
                                            blob
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    
                  }
                }
                SentryLogger.error3({
                      rootModule: "CreateProviderBrochureRequest",
                      subModulePath: /* [] */0,
                      value: "exec",
                      fullPath: "CreateProviderBrochureRequest.exec"
                    }, "Fetch::RequestError", [
                      "code",
                      response.status
                    ], [
                      "status",
                      response.statusText
                    ], [
                      "contentType",
                      "N/A"
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: undefined
                          });
              }));
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  exec ,
}
/* Csrf Not a pure module */
